<template>
  <van-pull-refresh v-model="isLoading" pulling-text="Tarik ke bawah untuk refresh..."
    loosing-text="Lepaskan untuk refresh..." success-text="Refresh Berhasil" @refresh="onRefresh">
    <div class="loan-status">
      <!-- <BaseHeaderBar :showLogOut="false" :showBackButton="true" /> -->
      <div class="content" @dblclick.prevent>
        <BRD :orderStatus="orderStatus"></BRD>
        <div class="loan-status-content" :style="loanStatusContentBackground()">
          <div class="status0" v-if="orderStatus === 0">
            <div class="iconList">
              <div>
                <img src="@/assets/img/status0_icon1.png" alt="" />
                <span>Manajemen Keuangan</span>
              </div>
              <div>
                <img src="@/assets/img/status0_icon2.png" alt="" />
                <span>Transfer</span>
              </div>
              <div>
                <img src="@/assets/img/status0_icon3.png" alt="" />
                <span>Transaksi</span>
              </div>
              <div>
                <img src="@/assets/img/status0_icon4.png" alt="" />
                <span>Tabungan</span>
              </div>
            </div>
            <img class="status0_xuxian" src="@/assets/img/status0_xuxian.png" alt="" />
            <div class="kapian">
              <div class="kapian_row1">Pinjaman</div>
              <div class="kapian_row2">
                Nominal pinjaman yang tersedia (lDR)
              </div>
              <div class="kapian_row3">Rp1.000.000~Rp4.700.000</div>
              <div class="kapian_row2">Tenor pinjaman 92~270Har</div>
              <div class="kapian_row4">Suku Bunga Tahunan 12.7%-14.6%</div>
              <button class="submit-btn" @click="submit">
                Segera Pengajuan
              </button>
            </div>
          </div>
          <div class="status1" v-if="orderStatus === 1 || orderStatus === 2 || orderStatus === 10">
            <img src="@/assets/img/jisushenhe.png" alt="" />
            <div>Dalam proses peninjauan</div>
            <span>Nominal pencairan dana tergantung pada hasil akhir evaluasi</span>
          </div>
          <div v-else-if="orderStatus === 3" class="status3">
            <img src="@/assets/img/fangkuanzhong.png" alt="" />
            <div class="wenan">Dalam proses pencairan</div>
            <div class="status3_daozhangjine">
              <div>
                <img src="@/assets/img/RP_icon.png" alt="" />
                <span>Jumlah yang diterima</span>
              </div>
              <p>Rp{{ formatNumberWithCommas(loanInfo.ardrrivalAmount) }}</p>
            </div>
            <div class="status3_bank">
              <p>Nama bank penerima</p>
              <p>{{ formatNumberWithCommas(loanInfo.ardrrivalBank) }}</p>
            </div>
            <div class="status3_bank">
              <p>Nomor rekening bank penerima</p>
              <p>
                {{ formatNumberWithCommas(loanInfo.ardrrivalBankcard) }}
              </p>
            </div>
          </div>

          <div v-else-if="orderStatus === 4" class="status4">
            <img src="@/assets/img/zanwufajieqian.png" alt="" />
            <div>Anda tidak dapat melakukan pinjaman saat ini</div>
            <span>
              Untuk saat ini Anda tidak memenuhi persyaratan. Pastikan
              <br />untuk selalu mempertahankan riwayat kredit yang baik.
            </span>
          </div>

          <div v-else-if="orderStatus === 5" class="status5">
            <div class="stdrandbyHint">
              <p v-for="(item, index) in loanInfo.stdrandbyHint" :key="index">
                {{ item }}
              </p>
            </div>
            <div class="state5_xiangqing">
              <div class="huankuanriqi">
                Harap pembayaran sebelum tanggal
                {{ loanInfo.bidrllDetails.redrpaymentTime.substr(0, 2) }}
                bulan
                {{ loanInfo.bidrllDetails.redrpaymentTime.substr(3, 2) }}
                tahun {{ loanInfo.bidrllDetails.redrpaymentTime.substr(6) }}
              </div>
              <div class="yuqitianshu">
                Sudah terlambat {{ loanInfo.bidrllDetails.ovdrerdueDay }} hari
              </div>
              <div class="haunkuanjine">
                <span>Nominal Pelunasan</span>
                <span>
                  Rp{{
                    formatNumberWithCommas(
                      loanInfo.bidrllDetails.fudrllrepaymentAmount
                    )
                  }}</span>
              </div>
              <div class="haunkuanjine">
                <span>Nominal Pembayaran Perpanjangan</span>
                <span>
                  Rp{{
                    formatNumberWithCommas(
                      loanInfo.bidrllDetails.exdrhibitionrepaymentaAount
                    )
                  }}</span>
              </div>
              <img class="fagndajing" src="@/assets/img/fagndajing.png" alt="" />
              <img class="state5_xiangqing_biaoti" src="@/assets/img/state5_xiangqing_biaoti.png" alt="" />
              <img class="state5_laba" src="@/assets/img/state5_laba.png" alt="" />
            </div>
            <RepaymenDetail></RepaymenDetail>
            <div class="handleBtn">
              <h3>Cek Kode Pembayaran</h3>
              <button type="button" class="confirm-btn" @click="showPopup(2)">
                Perpanjangan
              </button>
              <button type="button" class="confirm-btn" @click="showPopup(1)">
                Pelunasan
              </button>
            </div>

            <van-popup v-model="showPicker1" round position="bottom"
              :style="{ height: '400px', background: '#f3f5f7' }">
              <div class="popup">
                <div class="picker-toolbar">
                  <div @click="showPicker1 = false" class="picker-cancel">
                    <img src="@/assets/img/header-back-btn.png" />
                  </div>
                  pembayaran
                </div>
                <div class="repay-sort-picker-list">
                  <div class="item" v-for="item in bankList" :key="item.id" @click="selectRepaySort(item)">
                    <p class="label">{{ item.name }}</p>
                    <van-icon name="arrow" size="24" />
                  </div>
                </div>
              </div>
            </van-popup>
            <van-popup v-model="showPicker2" round position="bottom"
              :style="{ height: '400px', background: '#f3f5f7' }">
              <div class="popup">
                <div class="picker-toolbar">
                  <div @click="showPicker2 = false" class="picker-cancel">
                    <img src="@/assets/img/header-back-btn.png" />
                  </div>
                  Pilih metode pembayaran
                </div>
                <div class="repay-sort-picker-list">
                  <div class="item" v-for="item in selectedPayWay.payway" :key="item.pay_way" @click="goPay(item)">
                    <p class="label">{{ item.name }}</p>
                    <van-icon name="arrow" size="24" />
                  </div>
                </div>
              </div>
            </van-popup>
          </div>

          <div v-else-if="orderStatus === 6 || orderStatus === 7" class="status6">
            <div class="loan-status6_content">
              <div style="background: #155c2d">
                <div class="amountList">
                  <div class="loan-title">
                    <img src="@/assets/img/RP_icon.png" alt="" />
                    <p>Nominal Pinjaman</p>
                  </div>
                  <div class="xaunzhongjine">
                    Rp{{
                      formatNumberWithCommas(
                        loanInfo.mudrltipleLoanList[activeIndex].loanMoney
                      )
                    }}
                  </div>
                  <div class="loan-amount-sort">
                    <div ref="loanItem" v-for="(item, index) in loanInfo.mudrltipleLoanList" :key="index"
                      :class="{ active: activeIndex === index }" @click="selectLoan(item, index)">
                      Rp{{ formatNumberWithCommas(item.loanMoney) }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="info-block">
                <div v-if="loanTimers && loanTimers.length">
                  <template v-if="hasRate">
                    <div class="jiekuanqixian">
                      <div>
                        <img src="@/assets/img/jeikaunqixian_title_icon.png" alt="" />
                        <span>Tenor Pinjaman</span>
                      </div>
                      <span class="xainshizhi">{{ loanInfo.bidrgDeadate }}
                      </span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="jiekuanqixian">
                      <div>
                        <img src="@/assets/img/jeikaunqixian_title_icon.png" alt="" />
                        <span>Tenor Pinjaman</span>
                      </div>
                      <span class="xainshizhi">{{ loanTimers[0] }}Hari</span>
                    </div>
                  </template>
                </div>
                <div class="jiekuanqixian" v-if="hasRate">
                  <div>
                    <img src="@/assets/img/jeikaunqixian_title_icon.png" alt="" />
                    <span>Suku bunga tahunan</span>
                  </div>
                  <span class="xainshizhi">{{ loanInfo.dadryRate }}</span>
                </div>
              </div>
              <button type="button" class="submit" @click="submit">
                Segera Pengajuan
              </button>
            </div>
            <van-dialog v-model="visible" :showConfirmButton="false" close-on-click-overlay class="no-loan-dialog">
              <div class="dialog-title">Tingkatkan Nominal</div>
              <div class="dialog_body">
                <h3 class="tip-title">
                  Bagaimana cara menaikkan limit pinjaman
                </h3>
                <p class="tip">
                  Mempertahankan kebiasaan baik dan selalu melunasi tepat waktu.
                  Anda bisa mendapatkan kenaikan limit apabila menghindari
                  perilaku keterlambatan pembayaran.
                </p>
                <button type="button" class="confirm-btn" @click="onCloseDialog()">
                  Mengerti
                </button>
              </div>
            </van-dialog>
          </div>

          <div v-else-if="orderStatus === 8" class="status8">
            <img src="@/assets/img/renzhengshibai.png" alt="" />

            <div>Verifikasi KTP gagal</div>
            <span>
              Foto KTP yang Anda unggah tidak jelas, sehingga tidak dapat lolos
              evaluasi. Silakan unggah ulang.
            </span>
            <button type="button" class="submit-btn" @click="goAuthentication">
              Edit data
            </button>
          </div>

          <div v-else-if="orderStatus === 9" class="status9">
            <img src="@/assets/img/fuangkuanshibai.png" alt="" />
            <div>Pencairan gagal</div>
            <span>
              Pencairan tidak dapat dilakukan karena data rekening <br />
              bank Anda tidak tersedia. Silakan isi ulang.
            </span>
            <button type="button" class="submit-btn" @click="goBankInfo">
              Edit data
            </button>
            <van-dialog v-model="cancelLoanVisible" :showConfirmButton="false" close-on-click-overlay>
              <p class="cancel-loan-dialog-title">Berhasil dibatalkan</p>
              <p class="tip">
                Pinjaman Anda telah dibatalkan. Anda tidak perlu verifikasi data
                lagi jika ingin melakukan pinjaman.
              </p>
              <button type="button" class="confirm-btn" @click="onCloseDialog('cancelLoanVisible')">
                Mengerti
              </button>
            </van-dialog>
          </div>

          <div v-else-if="orderStatus === 12" class="loan-status12">
            <div class="jeikuan_max_box">
              <div class="jiekuan_box">
                <div class="jiekuanjine_title">
                  <img src="@/assets/img/RP_icon.png" alt="" />
                  <span>Jumlah pinjaman</span>
                </div>
                <div class="jiekuanjine">
                  Rp{{ formatNumberWithCommas(loanInfo.lodranAmount) }}
                </div>
              </div>
            </div>

            <div class="jiekuanqixian_box">
              <div class="jiekuanqixian_title">
                <img src="@/assets/img/jeikaunqixian_title_icon.png" alt="" />
                <span>Lama Pinjaman</span>
              </div>
              <span class="jiekuanqixian">
                {{ loanInfo.avdrailablePeriod[0] }} Hari
              </span>
            </div>

            <button type="button" class="withdraw-btn" @click="withdraw">
              Segera Tarik
            </button>
          </div>
        </div>
        <van-dialog v-model="feeVisible" :showConfirmButton="false" close-on-click-overlay>
          <div class="fee-detail">
            <p class="dialog-title fee-title">Rincian Biaya</p>
            <div>
              <p>Nominal Pinjaman</p>
              <p>Rp{{ formatNumberWithCommas(feeDetail.lodranCash) }}</p>
            </div>
            <div>
              <p>Jumlah Hari Pinjaman</p>
              <p>{{ feeDetail.lodranTimes }} Hari</p>
            </div>
            <div class="interest">
              <p>Total Bunga</p>
              <p>
                <!-- <span>{{ feeDetail.ordriginalAmount }}</span> -->
                <span>Rp{{ formatNumberWithCommas(feeDetail.todrtalCost) }}</span>
              </p>
              <!-- <div class="describe">
                Voucher pengurangan bunga telah otomatis digunakan (peningkatan
                jumlah pinjaman) {{ feeDetail.didrscountAmount }}
              </div> -->
            </div>
            <div v-if="orderStatus === 6 && !hasRate">
              <p>Saldo awal yang diterima</p>
              <p>
                Rp{{
                  formatNumberWithCommas(feeDetail.ordriginalamountReceived)
                }}
              </p>
            </div>
            <div v-if="orderStatus === 6 && !hasRate" style="color: #fb4f5d">
              <p>Mengurangi Bunga</p>
              <p>
                {{ feeDetail.lodrwerInterestrate }}
              </p>
            </div>
            <div v-if="orderStatus === 6 && !hasRate" style="color: #fb4f5d">
              <p>Saldo yang diterima sekarang</p>
              <p>Rp{{ formatNumberWithCommas(feeDetail.ardrrivalCash) }}</p>
            </div>
            <div v-if="orderStatus === 12 || (orderStatus === 6 && hasRate)">
              <p>Jumlah yang diterima</p>
              <p>Rp{{ formatNumberWithCommas(feeDetail.ardrrivalCash) }}</p>
            </div>
            <div v-if="orderStatus === 6 && !hasRate">
              <p>Rp{{ feeDetail.shdrowMsg }}</p>
            </div>
            <div class="footer-btn">
              <button type="button" class="cancel-btn" @click="onCloseDialog('feeVisible')">
                Batal
              </button>
              <button type="button" class="ok-btn submit-btn" @click="onOkFee">
                Konfirmasi
              </button>
            </div>
          </div>
        </van-dialog>
        <Tabbar></Tabbar>
      </div>
    </div>

    <template #loading>
      <span></span>
    </template>
  </van-pull-refresh>
</template>

<script>
import Vue from 'vue'
import { Dialog, Toast, PullRefresh, Overlay, Popup } from 'vant'

import request from '@/utils/request'
import dialogVisible from '@/mixins/dialogVisible'
import { getMetadata } from '@/utils/getEquipmentInfo.js'
import BRD from '@/components/BRD.vue'
import Tabbar from '@/components/Tabbar.vue'

import RepaymenDetail from './RepaymenDetail.vue'

Vue.use(Toast)
Vue.use(PullRefresh)
Vue.use(Overlay)
Vue.use(Popup)

export default {
  name: 'Home',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component,
    BRD,
    Tabbar,
    RepaymenDetail
  },
  data () {
    return {
      isLoading: false,
      cancelLoanVisible: false,
      feeVisible: false,
      orderStatus: '',
      loanInfo: {},
      feeDetail: {},
      activeIndex: -1,
      loanTimers: [],
      loanAmount: '',
      hasRate: false,
      amount: '',
      bankList: [],
      selectedPayWay: {},
      showPicker1: false,
      showPicker2: false,
      repayType: {}
    }
  },
  created () {
    this.getOrderStatus()
  },
  methods: {
    getOrderStatus (isRefresh) {
      request.post('homeDRInterface')
        .then(res => {
          this.orderStatus = res.ordrderStatus
          this.loanInfo = res
          // this.orderStatus = 5
          // this.loanInfo = {
          //   bidrllDetails: {
          //     redrpaymentTime: ''
          //   },
          //   lodranAmount: 10000,
          //   avdrailablePeriod: [10000]
          // }
          this.hasRate = res.ordrderStatus === 0 || (res.ordrderStatus === 7 && res.redr_loan === 0)
          if (res.ordrderStatus === 12) {
            this.loanAmount = String(res.lodranMaxAmount)
          }
          if (res.ordrderStatus === 0 || res.ordrderStatus === 6 || res.ordrderStatus === 7) {
            this.loanAmount = String(res.lodranMaxAmount)
            let index = -1
            // 过滤掉不能借的金额
            // if (res.ordrderStatus === 6 || (res.ordrderStatus === 7 && res.redr_loan === 1)) {
            //   this.loanInfo.mudrltipleLoanList = res.mudrltipleLoanList.filter((item) => {
            //     return item.loanStatus
            //   })
            // }
            res.mudrltipleLoanList.forEach((item, i) => {
              if (item.loanStatus) {
                index = i
              }
            })
            if (index > -1) {
              this.activeIndex = index
              this.selectedLoanItem = res.mudrltipleLoanList[index]
              this.loanAmount = String(this.selectedLoanItem.loanMoney)
              this.loanTimers = this.initLoanTimers = this.selectedLoanItem.loanTimers || []
            }
          }
          isRefresh && (this.isLoading = false)
        })
    },
    getPaymentBankList () {
      const postData = {
        redrpaymentType: this.repayType
      }
      request.post('padrybankList', postData)
        .then(res => {
          this.bankList = res.padrymentbankList
          this.showPicker1 = true
        })
    },
    getFeeDetail (item, loanType) {
      const postData = {
        lodranCash: item.loanMoney,
        lodranTimers: item.loanTimers[0],
        lodranType: loanType
      }
      this.withdrawType = loanType
      request.post('indrterestrateBomb', postData)
        .then(res => {
          this.feeDetail = res
        })
    },
    goRepaymenDetail () {
      this.$router.push('/repaymenDetail')
    },
    goBankInfo () {
      if (this.loanInfo.badrnkStatus) {
        const postData = {
          apdrplicationAmount: this.loanInfo.lodranAmount,
          prdromotionChannel: 'AppStore',
          portraitAdd: 1
        }
        // 获取设备信息
        getMetadata()
          .then(res => {
            // 提交设备信息
            return request.post('deviceInformation', res)
          })
          .then(() => {
            // 提交订单
            return request.post('sudrbmitloanOrder', postData)
          })
          .then(() => {
            this.getOrderStatus()
          })
      } else {
        this.goAuthentication()
      }
    },
    showPopup (repayType) {
      this.repayType = repayType
      this.getPaymentBankList()
    },
    goPay (item) {
      const { ordrderID, padrymentID, fudrllrepaymentAmount, exdrhibitionrepaymentaAount } = this.loanInfo.bidrllDetails
      this.$router.push({
        path: '/pay',
        query: {
          repayType: this.repayType,
          amount: this.repayType === 1 ? fudrllrepaymentAmount : exdrhibitionrepaymentaAount,
          orderId: ordrderID,
          payID: padrymentID,
          channelId: this.selectedPayWay.pay_id,
          payChannel: this.selectedPayWay.pay_channel,
          name: this.selectedPayWay.name,
          payWay: item.pay_way,
          payWayName: item.name
        }
      })
    },
    selectRepaySort (item) {
      this.selectedPayWay = item
      this.showPicker2 = true
    },
    selectLoan (item, index) {
      const loanStatus = item.loanStatus
      if (loanStatus) {
        if (index !== this.activeIndex) {
          this.activeIndex = index
          this.selectedLoanItem = item
          this.loanAmount = String(item.loanMoney)
          this.loanTimers = item.loanTimers
        }
      } else {
        this.visible = true
      }
    },
    submit () {
      if (this.selectedLoanItem) {
        if (this.orderStatus === 0 || (this.orderStatus === 7 && this.loanInfo.redr_loan === 0)) {
          this.$router.push({
            path: '/authentication',
            query: {
              amount: this.selectedLoanItem.loanMoney
            }
          })
        } else if (this.orderStatus === 6 || (this.orderStatus === 7 && this.loanInfo.redr_loan === 1)) {
          this.getFeeDetail(this.selectedLoanItem, 2)
          this.feeVisible = true
        }
      } else {
        Toast('Silahkan pilih pinjaman terlebih dahulu')
      }
    },
    onOkFee () {
      if (this.flag) return
      this.flag = true
      const { lodranCash, lodranTimes } = this.feeDetail
      const postData = {
        lodranAmount: lodranCash,
        lodranPeriod: lodranTimes,
        widrthdrawType: this.withdrawType
      }
      request.post('widrthdrawCash', postData)
        .then(() => {
          this.feeVisible = false
          this.orderStatus = 1
        })
        .finally(() => {
          this.flag = false
        })
    },
    goAuthentication () {
      this.$router.push({
        path: '/authentication',
        query: {
          amount: this.loanInfo.lodranAmount
        }
      })
    },
    withdraw () {
      const postData = {
        loanMoney: this.loanInfo.lodranAmount,
        loanTimers: this.loanInfo.avdrailablePeriod
      }
      this.getFeeDetail(postData, 1)
      this.feeVisible = true
    },
    onRefresh () {
      this.getOrderStatus(true)
    },
    goBack () {
      this.$router.back()
    },
    loanStatusContentBackground () {
      if (this.orderStatus === 6) {
        return {
          background: '#155c2d'
        }
      }
      if (this.orderStatus === 6) {
        return {
          background: '#cee4c8'
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.intro {
  padding-top: 437px;
  line-height: 42px;
  font-size: 30px;
  color: #302c48;
  text-align: center;
}

.content {
  position: relative;
  z-index: 2;
  border-radius: 30px 30px 0px 0px;
}

.loan-amount {
  display: flex;
  justify-content: center;
  margin-top: 23px;

  &>div {
    width: 50px;
    height: 71px;
    line-height: 71px;
    border-radius: 6px;
    font-size: 42px;
    color: #fd411c;
    text-align: center;
    background-color: #fff;

    &:nth-child(n + 2) {
      margin-left: 12px;
    }
  }
}

.time {
  .circle {
    display: flex;
    justify-content: center;

    &>div {
      width: 48px;
      height: 48px;
      border: 1px solid #dadada;
      border-radius: 50%;
      margin-top: 22px;

      &:nth-child(n + 2) {
        margin-left: 19px;
      }

      &.active {
        width: 67px;
        height: 67px;
        line-height: 67px;
        border: 2px solid #fd411c;
        margin-top: 12px;
        font-size: 32px;
        color: #fd411c;
        text-align: center;
      }
    }
  }

  .unit {
    line-height: 33px;
    margin-top: 9px;
    font-size: 24px;
    color: #fd411c;
    text-align: center;
  }
}

.loan-amount-sort {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(auto-fill, 200px);
  margin-bottom: 32px;

  div {
    margin-bottom: 23px;
    text-align: center;
    line-height: 64px;
    width: 198px;
    height: 64px;
    background: #ffffff;
    border-radius: 35px;
    border: 1px solid #fff;
    font-size: 30px;
    color: #333333;
    font-weight: 300;

    &.active {
      font-weight: 600;
      border-color: #aaa;
    }
  }
}

.loan-status {
  display: flex;
  flex-direction: column;
  background-color: @body-bg-color;
}

.loan-status-content {
  // 340是最顶部邮箱装饰图片的高度  150px是margin-top  160px是padding-top
  min-height: calc(100vh - 340px + 150px - 160px);
  border-radius: 78px 78px 0 0;
  margin-top: -150px;
  padding-top: 160px;
  // margin-top: -50px;
}

.status0 {
  padding: 0 30px;
  border-radius: 78px 78px 0 0;
  min-height: calc(100vh - 340px + 150px - 160px);
  background: #f7f7f7;
  padding-top: 110px;
  margin-top: 10px;

  .iconList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 26px;

    div {
      width: 25%;

      img {
        display: block;
        margin: 0 auto;
        width: 61px;
        height: 57px;
        margin-bottom: 15px;
      }

      span {
        display: block;
        font-weight: 400;
        font-size: 26px;
        color: #333333;
        // 超出显示省略号
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }
    }
  }

  .status0_xuxian {
    width: 100%;
    display: block;
    margin: 30px 0;
  }

  .kapian {
    position: relative;
    background: #fff;
    padding: 0 25px;
    padding-top: 28px;
    font-weight: 400;
    font-size: 24px;
    color: #333333;

    .kapian_row1 {
      font-size: 70px;
      font-weight: bold;
      margin-bottom: 11px;
    }

    .kapian_row2 {
      margin-bottom: 16px;
    }

    .kapian_row3 {
      color: #ff0707;
      margin-bottom: 17px;
    }

    .kapian_row4 {
      padding-bottom: 38px;
    }

    .submit-btn {
      position: absolute;
      left: 0;
      right: 0;
      border-radius: 0;
    }
  }
}

.status1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  background: #ffffff;
  border-radius: 78px 78px 0 0;
  min-height: calc(100vh - 340px + 150px - 160px);

  img {
    margin-top: 90px;
    margin-bottom: 84px;
    margin-top: 120px;
    width: 680px;
    height: 404px;
    text-align: center;
  }

  div {
    font-weight: 500;
    font-size: 32px;
    color: #333333;
    margin-bottom: 38px;
    text-align: center;
  }

  span {
    font-weight: 400;
    font-size: 24px;
    color: #999999;
    text-align: center;
  }
}

.status3 {
  font-size: 24px;
  text-align: center;
  border-radius: 78px 78px 0 0;
  min-height: calc(100vh - 340px + 150px - 160px);
  background: #ffffff;
  padding-top: 120px;

  img {
    display: block;
    width: 227px;
    height: 247px;
    margin: 30px auto;
  }

  .wenan {
    font-weight: 500;
    font-size: 32px;
    color: #333333;
  }

  .status3_daozhangjine {
    margin-top: 100px;
    margin-bottom: 30px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 30px;
      color: #333333;

      img {
        width: 42px;
        height: 42px;
        margin: 0;
        margin-right: 10px;
      }
    }

    p {
      font-size: 54px;
      color: #333333;
      line-height: 62px;
    }
  }

  .status3_bank {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 665px;
    background: #f4f0e1;
    border-radius: 18px;
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 31px 24px;
    font-weight: 400;
    font-size: 24px;
    color: #333333;
  }
}

.status4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 78px 78px 0 0;
  min-height: calc(100vh - 340px + 150px - 160px);
  background: #ffffff;
  padding-top: 120px;

  img {
    width: 442px;
    margin-top: 30px;
  }

  div {
    font-weight: 500;
    font-size: 30px;
    color: #333333;
    margin-top: 42px;
    margin-bottom: 24px;
  }

  span {
    font-weight: 400;
    font-size: 24px;
    color: #666666;
  }
}

.status5 {
  .stdrandbyHint {
    margin-top: 120px;
    padding: 0 30px;
    padding-bottom: 10px;

    font-weight: 400;
    font-size: 26px;
    color: #ffffff;
    line-height: 37px;
  }

  .state5_xiangqing {
    position: relative;
    margin: 0 15px;
    background: url("~@/assets/img/state5_xiangqing.png");
    background-size: 100% 100%;
    padding: 30px 0;
    margin-top: 80px;

    .huankuanriqi {
      margin: 0 auto;
      width: 450px;
      font-weight: 400;
      font-size: 26px;
      color: #1f1f1e;
      text-align: center;
      margin-bottom: 30px;
    }

    .yuqitianshu {
      font-weight: 400;
      font-size: 26px;
      color: red;
      text-align: center;
    }

    .haunkuanjine {
      padding: 20px 0;
      width: 548px;
      background: #eef7f2;
      border-radius: 1px;
      font-weight: 400;
      font-size: 26px;
      color: #333333;
      margin: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      border-radius: 20px;

      span:nth-child(1) {
        font-weight: 600;
      }
    }

    .fagndajing {
      position: absolute;
      width: 143px;
      top: -80px;
    }

    .state5_xiangqing_biaoti {
      position: absolute;
      width: 287px;
      top: -60px;
      left: 170px;
    }

    .state5_laba {
      position: absolute;
      width: 109px;
      top: 50px;
      right: 70px;
    }
  }

  .handleBtn {
    height: 450px;
    margin-top: 20px;
    padding: 40px 30px;
    background: #fff;

    h3 {
      font-weight: 600;
      font-size: 26px;
      color: #333333;
      text-align: center;
      margin-bottom: 54px;
    }

    .confirm-btn {
      .submit-btn;
      margin-bottom: 20px;
      background: #329f55;
    }

    .confirm-btn:last-child {
      .submit-btn;
    }
  }
}

.status6 {
  padding-bottom: 300px;
  background: #f8f8f8;
  min-height: calc(100vh - 340px + 150px - 160px);

  .amountList {
    background: url("../assets/img/amountList_bg.png");
    background-size: 100% 100%;
    padding-bottom: 20px;
    z-index: 2;
    position: relative;
    padding-top: 130px;

    .loan-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 30px;
      color: #333333;
      margin-bottom: 30px;

      img {
        width: 42px;
        margin-right: 10px;
      }
    }
  }

  .xaunzhongjine {
    font-size: 54px;
    color: #333333;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }

  .info-block {
    position: relative;
    z-index: 1;
    background: #fff;
    margin-top: -30px;
    padding-top: 30px;

    .jiekuanqixian {
      padding: 30px;
      padding-bottom: 30px;
      font-size: 24px;

      div {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        img {
          margin-right: 10px;
          width: 32px;
        }

        span {
          font-weight: 400;
          font-size: 30px;
          color: #333333;
        }
      }

      .xainshizhi {
        font-weight: 500;
        font-size: 30px;
        color: #333333;
        background-size: 100% 100%;
        height: 70px;
        line-height: 70px;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 100px;
        padding: 0 20px;
        // 增加阴影
        box-shadow: 0px 0px 20px rgba(190, 228, 180, 1);
      }
    }
  }

  .submit {
    .submit-btn;
    width: 650px;
    position: fixed;
    left: 50%;
    bottom: 200px;
    transform: translateX(-50%);
    z-index: 2;
  }

  .no-loan-dialog {
    width: 564px;
    margin-left: auto;
    margin-right: auto;

    .dialog_body {
      padding: 20px;
      padding-bottom: 0;
    }
  }

  .tip-title {
    margin-bottom: 26px;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    color: #333333;
  }

  .tip {
    text-align: center;
    font-weight: 400;
    font-size: 26px;
    color: #999999;
    line-height: 36px;
  }

  .confirm-btn {
    .submit-btn;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.status8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  border-radius: 78px 78px 0 0;
  min-height: calc(100vh - 340px + 150px - 160px);
  background: #ffffff;
  padding-top: 120px;

  img {
    width: 527px;
    margin-top: 30px;
  }

  div {
    font-weight: 500;
    font-size: 30px;
    color: #333333;
    margin-top: 42px;
    margin-bottom: 24px;
  }

  span {
    font-weight: 400;
    font-size: 24px;
    color: #666666;
    margin-bottom: 40px;
  }
}

.status9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  border-radius: 78px 78px 0 0;
  min-height: calc(100vh - 340px + 150px - 160px);
  background: #ffffff;
  padding-top: 120px;

  img {
    width: 327px;
    margin-top: 30px;
  }

  div {
    font-weight: 500;
    font-size: 30px;
    color: #333333;
    margin-top: 42px;
    margin-bottom: 24px;
  }

  span {
    font-weight: 400;
    font-size: 24px;
    color: #666666;
    margin-bottom: 40px;
  }
}

.loan-status12 {
  min-height: calc(100vh - 340px + 150px - 160px);
  background: #f8f8f8;
  padding-bottom: 120px;

  .jeikuan_max_box {
    background: #155c2d;

    .jiekuan_box {
      border-radius: 78px 78px 0 0;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 400;
      font-size: 30px;
      color: #333333;
      background: url("~@/assets/img/state12_jiekuanjine.png");
      background-size: 100% 100%;
      height: 300px;
      padding-top: 120px;

      .jiekuanjine_title {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        img {
          width: 42px;
          height: 42px;
          margin-right: 10px;
        }
      }

      .jiekuanjine {
        font-size: 54px;
        color: #333333;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .jiekuanqixian_box {
    padding: 40px;
    background: #fff;

    .jiekuanqixian_title {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 30px;
      color: #333333;

      img {
        width: 32px;
        margin-right: 10px;
      }
    }

    .jiekuanqixian {
      font-weight: 500;
      font-size: 30px;
      color: #333333;
      background-size: 100% 100%;
      height: 70px;
      line-height: 70px;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 100px;
      padding: 0 20px;
      // 增加阴影
      box-shadow: 0px 0px 20px rgba(190, 228, 180, 1);
    }
  }

  .withdraw-btn {
    .submit-btn;
    width: 650px;
    margin-top: 130px;
  }
}

.fee-title {
  margin-bottom: 50px;
}

.fee-detail {
  margin: 0 auto;

  &>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 500;
    color: #333333;

    p {
      font-weight: 400;
      font-size: 26px;
    }
  }

  .interest {
    position: relative;

    // padding-bottom: 60px;
    // span:nth-child(1) {
    //   font-weight: 500;
    //   text-decoration: line-through;
    //   font-size: 26px;
    //   margin-right: 10px;
    // }
    div {
      position: absolute;
      bottom: -28px;
    }
  }

  .describe {
    margin-bottom: -5px;
    font-size: 26px;
    color: #ff8300;
    line-height: 28px;
  }
}

.footer-btn {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 45px;
  gap: 20px;
}

.popup {
  height: 400px;
  background: #f3f5f7;

  .picker-toolbar {
    position: relative;
    width: 100%;
    height: 88px;
    line-height: 88px;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    background: #fff;
  }

  .picker-cancel {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 30px;
    cursor: pointer;

    img {
      width: 24px;
      height: 40px;
    }
  }

  .repay-sort-picker-list {
    padding: 30px 30px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 43px;
      padding-right: 33px;
      height: 94px;
      background: #fff;

      &:nth-child(n + 2) {
        margin-top: 32px;
      }

      p {
        font-size: 36px;
        font-weight: 500;
      }
    }
  }
}
</style>
