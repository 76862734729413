<template>
  <div>
    <div class="logoutText">
      <span @click="visibleLogOut = true">Keluar</span>
    </div>
    <div class="orderStatus_decorate" >
      <img class="decorate1" src="@/assets/img/decorate1.png" alt="" />
      <img class="decorate2" src="@/assets/img/decorate2.png" alt="" />
      <div class="Service">
        <p>Customer Service</p>
        <p>csbrd002@outlook.com</p>
      </div>
    </div>
    <van-dialog
      v-model="visibleLogOut"
      show-cancel-button
      cancel-button-text="Batal"
      confirm-button-text="Setuju"
      @cancel="visibleLogOut = false"
      @confirm="logout"
    >
      <h3 class="logout_text">yakin untuk keluar?</h3>
    </van-dialog>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import store from '@/store'
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  props: {
    orderStatus: {

    }
  },
  data () {
    return {
      visibleLogOut: false
    }
  },
  methods: {
    logout () {
      store.commit('recordInfo', {
        type: 'userInfo',
        value: {}
      })
      localStorage.removeItem('userInfo')
      this.$router.push({
        path: '/login'
      })
      location.reload()
    }
  }
}
</script>

<style scoped lang='less'>
.logoutText {
  font-size: 26px;
  text-align: right;
  font-weight: 600;
  padding-top: 20px;
  padding-right: 30px;
  color: #fff;
  span {
    border-bottom: 1px solid #fff;
  }
}
.logout_text {
  color: #000;
  font-size: 46px;
  margin: 60px 0px;
  text-align: center;
}
.orderStatus0_decorate,
.orderStatus_decorate {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-top: 20px;
  height: 320px;
  .back-btn {
    position: absolute;
    top: 40px;
    width: 25px;
  }
  .decorate1 {
    width: 300px;
  }
  .decorate2 {
    width: 400px;
  }
  .Service {
    position: absolute;
    bottom: 10px;
    font-weight: 400;
    font-size: 30px;
    p:nth-child(1) {
      font-weight: 600;
    }
  }
}
.orderStatus0_decorate {
  z-index: 2;
  .Service {
    color: #000;
  }
}
.orderStatus_decorate {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 160px;
  padding-top: 60px;

  .decorate1 {
    margin-bottom: 130px;
  }
  .decorate2 {
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 3;
  }
  .Service {
    bottom: 10px;
    color: #fff;
  }
}
</style>
